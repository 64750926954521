(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("mobxReact"), require("gsap"), require("sbRespBlockLib"), require("sbInternalMsgBus"), require("ReactDOM"), require("mobxUtils"), require("ReactTransitionGroup"), require("sbJsExtends"), require("sbDataLayer"));
	else if(typeof define === 'function' && define.amd)
		define("sb-responsive-marketboard-block", ["React", "mobx", "mobxReact", "gsap", "sbRespBlockLib", "sbInternalMsgBus", "ReactDOM", "mobxUtils", "ReactTransitionGroup", "sbJsExtends", "sbDataLayer"], factory);
	else if(typeof exports === 'object')
		exports["sb-responsive-marketboard-block"] = factory(require("React"), require("mobx"), require("mobxReact"), require("gsap"), require("sbRespBlockLib"), require("sbInternalMsgBus"), require("ReactDOM"), require("mobxUtils"), require("ReactTransitionGroup"), require("sbJsExtends"), require("sbDataLayer"));
	else
		root["sb-responsive-marketboard-block"] = factory(root["React"], root["mobx"], root["mobxReact"], root["gsap"], root["sbRespBlockLib"], root["sbInternalMsgBus"], root["ReactDOM"], root["mobxUtils"], root["ReactTransitionGroup"], root["sbJsExtends"], root["sbDataLayer"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__77__, __WEBPACK_EXTERNAL_MODULE__137__, __WEBPACK_EXTERNAL_MODULE__186__) {
return 